import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import moment from 'moment'
import 'element-ui/lib/theme-chalk/index.css'
// // 国际化
import VueI18n from 'vue-i18n'
// // 语言包
import messages from '@/assets/lang/index.js'

Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueI18n)
// 国际化
const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'CMN', // 设置语言
  messages, // 语言包
})
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
