import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
export default {
  common: {
    OrderId: '订单号',
    OrderDetail: '订单详情',
    Language: '语言',
    FBUserID: 'FB会员ID',
    ChannelUserID: '渠道会员ID',
    Status: '状态',
    Type: '类型',
    Currency: '币种',
    Stake: '投注额',
    mystake: '名义投注额',
    myCashoutStake: '名义提前结算本金',
    kkstake: '扣款额',
    CompanyWinLoss: '公司输赢',
    IP: 'IP',
    OrderTime: '订单时间',
    BetDetail: '投注详情',
    CashoutDetail: '提前结算详情',
    TransactionDetail: '交易详情',
    CashoutCount: '提前结算次数',
    CashoutID: '提前结算ID',
    CashoutTime: '提前结算时间',
    CashoutStake: '实际提前结算本金',
    ML: '[马来赔]',
    YN: '[印尼赔]',
    MN: '[美式赔]',
    CashoutReturn: '提前结算返还额',
    CashoutStatus: '提前结算状态',
    TransactionTime: '交易时间',
    TransactionID: '交易ID',
    TransactionStatus: '交易状态',
    Amount: '交易金额',
    Balance: '余额',
    Successful: '成功',
    Failed: '失败',
    Match: '赛事',
    Seletion: '选项',
    Result: '结果',
    Odds: '赔率',
    SettleStake: '正常结算本金',
    timeZone: '时区',
    SettleReturn: '正常结算返还额',
    SettleTime: '结算时间',
    parlay: '串关',
    single: '单关',
    Created: '创建',
    Confirming: '确认中',
    Rejected: '已拒绝',
    Canceled: '取消',
    Confirmed: '已确认',
    Settled: '已结算',
    EURO: '[欧赔]',
    HK: '[港赔]',
    CompanyWinLoss1: '公司输赢（提前结算）',
    IN: '转入',
    OUT: '转出',
    BET: '投注',
    WIN: '派彩',
    REFUND: '拒单退款',
    CASHOUT: '提前结算返还',
    CANCEL_DEDUCT: '订单取消补扣',
    CANCEL_RETURN: '订单取消返还',
    SETTLEMENT_ROLLBACK_RETURN: '结算回滚返还',
    SETTLEMENT_ROLLBACK_DEDUCT: '结算回滚补扣',
    CASHOUT_CANCEL_DEDUCT: '提前结算订单取消补扣',
    CASHOUT_CANCEL_RETURN: '提前结算订单取消返还',
    CASHOUT_CANCEL_ROLLBACK_DEDUCT: '提前结算回滚补扣',
    CASHOUT_CANCEL_ROLLBACK_RETURN: '提前结算回滚返还',
    Result1: '赛果',
    PreMatch: '赛前',
    Live: '滚球',
    Cancel: '取消',
    Push: '和',
    Loss: '输',
    Win: '赢',
    WinReturn: '赢半',
    LossReturn: '输半',
  },
  ...zhLocale,
}
