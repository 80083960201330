import zhLocale from 'element-ui/lib/locale/lang/en'
export default {
  common: {
    OrderId: 'Order Id',
    OrderDetail: 'Order Detail',
    Language: 'Language',
    FBUserID: 'FB User ID',
    ChannelUserID: 'Channel  User ID',
    Status: 'Status',
    Type: 'Type',
    Currency: 'Currency',
    Stake: 'Stake',
    mystake: 'Nominal Bet Amount',
    kkstake: 'Deduction Amount',
    CompanyWinLoss: 'Company  Win Loss',
    IP: 'IP',
    OrderTime: 'Order Time',
    ML: 'Malay',
    YN: 'Indo',
    MN: 'American',
    myCashoutStake: 'Nominal CashOut Stake',
    timeZone: 'Time Zone',
    BetDetail: 'Bet Detail',
    CashoutDetail: 'Cashout Detail',
    TransactionDetail: 'Transaction Detail',
    CashoutCount: 'Cashout Count',
    CashoutID: 'Cashout ID',
    CashoutTime: 'Cashout Time',
    CashoutStake: 'Cashout Stake',
    CashoutReturn: 'Cashout Return',
    CashoutStatus: 'Cashout Status',
    TransactionTime: 'Transaction Time',
    TransactionID: 'Transaction ID',
    TransactionStatus: 'Transaction Status',
    Amount: 'Amount',
    Balance: 'Balance',
    Successful: 'Successful',
    Failed: 'Failed',
    Match: 'Match',
    Seletion: 'Seletion',
    Result: 'Result',
    Odds: 'Odds',
    SettleStake: 'Settle Stake',
    SettleReturn: 'Settle Return',
    SettleTime: 'Settle Time',
    parlay: 'Parlay',
    single: 'Single',
    Created: 'Created',
    Confirming: 'Confirming',
    Rejected: 'Rejected',
    Canceled: 'Canceled',
    Confirmed: 'Confirmed',
    Settled: 'Settled',
    EURO: '[EU]',
    HK: '[HK]',
    CompanyWinLoss1: 'Company Win Loss(Cashout)',
    IN: '[IN]',
    OUT: 'OUT',
    BET: 'BET',
    WIN: 'WIN',
    REFUND: 'REFUND',
    CASHOUT: 'CASHOUT',
    CANCEL_DEDUCT: 'CANCEL_DEDUCT',
    CANCEL_RETURN: 'CANCEL_RETURN',
    SETTLEMENT_ROLLBACK_RETURN: 'SETTLEMENT_ROLLBACK_RETURN',
    SETTLEMENT_ROLLBACK_DEDUCT: 'SETTLEMENT_ROLLBACK_DEDUCT',
    CASHOUT_CANCEL_DEDUCT: 'CASHOUT_CANCEL_DEDUCT',
    CASHOUT_CANCEL_RETURN: 'CASHOUT_CANCEL_RETURN',
    CASHOUT_CANCEL_ROLLBACK_DEDUCT: 'CASHOUT_CANCEL_ROLLBACK_DEDUCT',
    CASHOUT_CANCEL_ROLLBACK_RETURN: 'CASHOUT_CANCEL_ROLLBACK_RETURN',
    Result1: 'Result',
    PreMatch: 'PreMatch',
    Live: 'Live',
    Cancel: 'Cancel',
    Push: 'Push',
    Loss: 'Loss',
    Win: 'Win',
    WinReturn: 'Win Return',
    LossReturn: 'Loss Return',
  },
  ...zhLocale,
}
